.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
  .cls-1 {
    fill: #876035;
  }

  .cls-2 {
    fill: #c1272d;
  }

  .cls-3 {
    fill: #39b54a;
  }



  .cls-4 {
    fill: #9f43ad;
  }


  .tablewr{
    height: 70vh;
    overflow: scroll;
  }

  .act{
    fill: #876035 !important;
    background-color: #876035 !important;
  }

.detajetbtn{
     border: 0px solid black;
     border-radius: 4px;
     background-color: transparent;
}
