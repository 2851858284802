.sec4bg {
    background-color: white;
}

@media(max-width:567px){
    .imgwrap1{
        background-image: url('../../images/1ph.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 45vh !important;
        border: 2px solid black;
        text-align: center;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        border: none;
    }

    .centered-project{
        left: 41% !important;
    }

    .imgwrap2{
        height: 45vh !important;
    }

    .centered-project2{
        top: 38% !important;
        left:50% !important;
    }
}

.centered-project {
    background-color: rgba(41, 42, 44, 0.7);
    color: white;
   padding: 10px;
   border-radius: 8px;
   display: flex;
   align-items: center;
    position: relative;
    cursor: pointer;
    top: 43%;
    left: 20%;
    transform: translate(-50%, -50%);
    height: 50px;
}

.centered-project2 {
    background-color: rgba(41, 42, 44, 0.7);
    color: white;
   padding: 10px;
   border-radius: 8px;
   display: flex;
   align-items: center;
    position: relative;
    cursor: pointer;
    top: 43%;
    left:36%;
    transform: translate(-50%, -50%);
    height: 50px;
}

.centered-project3 {
    background-color: rgba(41, 42, 44, 0.7);

    color: white;
   padding: 10px;
   border-radius: 8px;
   display: flex;
   align-items: center;
    position: relative;
    cursor: pointer;
    top: 55%;
    left:19%;
    transform: translate(-50%, -50%);
    height: 50px;
}

.imgwrap1 {
    background-image: url('../../images/1ph.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 75vh;
    border: 2px solid black;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    border: none;
}

.imgwrap2 {
    background-image: url('../../images/banesa.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 75vh;
    border: 2px solid black;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    border: none;
}

.in4 {
    border-radius: 20px;
    border: none;
}