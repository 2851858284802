.navi{
    background-color: #292A2C !important;
}

@media(min-width= 1100px){
      .navi{
        padding-left: 2rem m !important;
      }
}


.nav-link{
    font-size: large !important;
    padding: 0.5rem !important;
}

.lang{
    display: flex !important;
}

@media(min-width:576px){
    .bre{
        border-right: 3px solid white;
    }
    
}

@media (max-width: 992px) {
    .navbar-collapse {
      position: fixed;
      top: 0;
      left: -100%;
      bottom: 0;
      width: 80%;
      background-color: #343a40;
      padding: 2rem;
      transition: left 0.3s ease;
    }
  
    .navbar-collapse.show {
      left: 0;
    }
  }


  .navbar {
    position: relative;
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .navbar-toggler {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
  
  .navbar-text {
    display: flex;
    align-items: center;
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background-color: #343a40;
    color: white;
    z-index: 999;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  
  .sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 20px;
  }
  
  .sidebar-menu li {
    margin-bottom: 10px;
  }
  
  .sidebar-menu li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .sidebar-close svg {
    fill: white;
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .navbar-collapse {
      display: flex !important;
      justify-content: flex-end;
    }
  
    .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .sidebar-menu li {
        text-align: start;
      }
    .navbar-nav {
      display: none;
    }
  
    .navbar-toggler.active .navbar-toggler-icon {
      background-color: transparent;
    }
  
    .sidebar {
      transform: translateX(0);
    }
  
    .sidebar-close {
      top: 20px;
      right: 20px;
    }
  }

 